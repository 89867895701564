import React from "react";
import { useTranslation } from "react-i18next";
import { GetStartedButton, StyledRegisterCTA } from "./index.style";
import { SIGNUP_URL } from "constants/paths";
import SafeLink from "components/core/SafeLink";
import Icon from "components/core/Icon";

export interface RegisterCTAProps {
  className?: string;
  utmParams?: Record<string, string>;
}

const RegisterCTA = (props: RegisterCTAProps) => {
  const { t } = useTranslation();

  const getSignupUrl = () => {
    const url = new URL(SIGNUP_URL);

    // Base UTM parameters if passed explicitly
    const baseUtmParams = props.utmParams || {};

    // Customize UTM params for blog pages
    if (
      typeof window !== "undefined" &&
      window.location.pathname.includes("/blog/")
    ) {
      baseUtmParams.utm_source = "Customerly";
      baseUtmParams.utm_medium = "blog";
    }

    // Append UTM parameters if they exist
    Object.entries(baseUtmParams).forEach(([key, value]) => {
      if (value) {
        url.searchParams.append(key, value);
      }
    });

    return url.toString();
  };

  return (
    <StyledRegisterCTA className={props.className}>
      <GetStartedButton as={SafeLink} to={getSignupUrl()}>
        {t("components.ui.extended.RegisterCTA.getStarted")}
        <Icon size={12} type={"arrow-right"} />
      </GetStartedButton>
    </StyledRegisterCTA>
  );
};

export default RegisterCTA;
