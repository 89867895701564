import React from "react";
import Service from "templates/service";
import Heading from "components/core/Typography/Heading";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  PINTEREST_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from "constants/social";

export interface PrivacyProps {
  location: Location;
}

export default ({ location }: PrivacyProps) => {
  const { t } = useTranslation();

  return (
    <Service
      title={t("pages.privacy.title")}
      subtitle={t("pages.privacy.subtitle")}
      seoProps={{
        title: t("pages.privacy.seo.title"),
        description: t("pages.privacy.seo.description"),
        image: {
          relativePath: "meta/customerly-live-chat-privacy.jpg",
          alt: "Customerly Privacy Policy",
        },
        structuredData: [
          {
            "@context": "https://schema.org",
            "@type": "Corporation",
            brand: "Customerly",
            name: "Live Chat Software",
            sameAs: [
              FACEBOOK_URL,
              INSTAGRAM_URL,
              LINKEDIN_URL,
              PINTEREST_URL,
              TWITTER_URL,
              YOUTUBE_URL,
            ],
            url: "https://www.customerly.io/",
          },
        ],
      }}
      location={location}
    >
      <Heading level={1}>Your privacy is key to us</Heading>
      <p>
        We respect the privacy rights of our users and recognize the importance
        of protecting the personal information we collect about you and your
        customers.
        <br />
        Our Privacy Policy is designed to help you understand what information
        we collect and how we use and share that information.
        <br />
        User data is safely stored in Europe. The Customerly infrastructure is
        hosted in Ireland. The Customerly live chat infrastructure is hosted in
        Ireland. User data is stored in our core infrastructure. We do not
        transfer user data outside of the EU.
      </p>
      <Heading level={2}>1. Respect of Privacy</Heading>
      <p>This is what we do to protect your privacy:</p>
      <ul>
        <li>
          Before or at the time of collecting personal information, we will
          identify the purposes for which information is being collected.
        </li>
        <li>
          We will collect and use of personal information only with the
          objective of fulfilling those purposes specified by us and for other
          compatible purposes, unless we obtain the consent of the individual
          concerned or as required by law.
        </li>
        <li>
          We collect data about visitors of websites using the Customerly Live
          chat. This data is collected anonymously and is not directly bound to
          any identifiable user, whether it be its personal identity, or its
          network information.
        </li>
        <li>
          We will only retain personal information as long as necessary for the
          fulfillment of those purposes.
        </li>
        <li>
          We will collect personal information by lawful and fair means and,
          where appropriate, with the knowledge or consent of the individual
          concerned.
        </li>
        <li>
          Personal data should be relevant to the purposes for which it is to be
          used, and, to the extent necessary for those purposes, should be
          accurate, complete, and up-to-date.
        </li>
        <li>
          We will protect personal information by reasonable security safeguards
          against loss or theft, as well as unauthorized access, disclosure,
          copying, use or modification.
        </li>
        <li>
          We will make readily available to customers information about our
          policies and practices relating to the management of personal
          information.
        </li>
      </ul>
      To understand how we process your business related data, please read the
      full{" "}
      <a href="https://www.iubenda.com/privacy-policy/45634728">
        {" "}
        privacy policy here
      </a>
      . <br />
      <br />
      <strong>
        Note: This is only for your personal data. To understand how we process
        your customer data, please read below.
      </strong>
      <Heading level={2}>2. Privacy of our customers' end-users</Heading>
      <p>This is what we do to protect your customers privacy:</p>
      <ul>
        <li>
          We collect data about visitors of websites using the Customerly Live
          chat for the only purpose of providing services to our clients.
        </li>
        <li>We will never share your customer data to anyone.</li>
        <li>
          We will never use any of your customer data for marketing purposes.
        </li>
        <li>
          We will never use any cookie, personal email or IP address to run
          marketing campaign anywhere.
        </li>
      </ul>
      <Heading level={2}>3. GDPR</Heading>
      <p>
        Customerly strictly implements the GDPR regulation, that aims at
        protecting user data and providing a right to modify and delete such
        data, as well as to consent to data collection. You can find our full
        <Link to="/gdpr/"> GDPR-oriented privacy policy</Link>. Our users can
        sign our{" "}
        <Link to="/assets/legals/DPA.pdf"> Data Processing Agreement</Link> with
        us and send it to legal@customerly.io.
        <br />
        Here you can find our
        <Link to="/subprocessors/"> subprocessors list</Link>.
      </p>
      <Heading level={2}>4. Data Usage for Analytics (Anonymous Data)</Heading>
      <p>
        While no sensitive or identifiable data of your customers’ end-users is
        shared, Customerly uses anonymized data to improve our services and gain
        usage insights. The following services are utilized:
      </p>
      <ul>
        <li>
          <strong>Clearbit</strong>: For business data enrichment.
        </li>
        <li>
          <strong>Clarity</strong>: To monitor anonymous user interaction data.
        </li>
        <li>
          <strong>Google Analytics</strong>: For aggregated and anonymized
          product usage analytics.
        </li>
        <li>
          <strong>Mixpanel</strong>: For analyzing user behavior to enhance the
          product experience.
        </li>
      </ul>
      <p>
        All data passed to these services is fully anonymized, ensuring no
        identifiable personal information is shared.
      </p>
      <Heading level={2}>5. Live Chat Cookie & IP Policy</Heading>
      <p>
        The Customerly <Link to="/live-chat/">live chat</Link>, that is running
        on the websites of our users, uses cookies. Cookies are solely used in
        accordance with those points:
      </p>
      <ul>
        <li>
          Cookies are necessary for live chat functionality. They are needed to
          restore the chat session and messages of a live chat user when
          browsing between website pages and/or coming back on the website a few
          days after.
        </li>
        <li>
          Cookies have a default expiration time of 12 months, which is renewed
          if and when the user comes back to the website and loads the live
          chat.
        </li>
        <li>
          Cookies bind an user to a single session. If that session contains
          messages, it is permanent (unless deleted by a website agent);
          otherwise the session is temporary and is destroyed 30 minutes after
          the last website access.
        </li>
        <li>
          Cookies are not used for tracking purposes. They are solely used to
          bind an user to a server-side session storage, which is then used for
          messaging purposes, in the event either the user or a website agent
          starts a conversation.
        </li>
        <li>
          The user IP address is stored in the server-side session storage
          that's bound to the cookie. If the user leaves without using the live
          chat messaging features we never store any session cookies.
        </li>
        <li>
          The user IP address is kept indefinitely in the event the user started
          a chat session with a website on Customerly. We are legally required
          by the law of Ireland to log those IPs in the event of a legal
          request. Though, we keep those IP address longer as we need to
          aggregate them to protect our live chat service against botnets and
          spam attacks, which occur frequently. The Customerly service could not
          function at the level our customers expect from us without statistics
          on those collected IP.
        </li>
      </ul>
      <Heading level={3}>Payment information</Heading>
      <p>
        When you make a purchase, any credit card information you provide as
        part of your payment information is collected and processed directly by
        our payment gateway Stripe. <br />
        <strong>We never store your full credit card information.</strong>
        <br />
        Stripe commits to complying with the Payment Card Industry Data Security
        Standard (PCI-DSS) and using industry standard security. <br />
        Stripe may use your Payment Information in accordance with their own
        Privacy Policy here:{" "}
        <a href="http://stripe.com/us/checkout/legal">
          https://stripe.com/us/checkout/legal
        </a>{" "}
        .
      </p>
      <Heading level={3}>Your Controls and Choices</Heading>
      <p>
        <strong>Opt-Outs and Communication Preferences. </strong>We always
        provide you the opportunity to “opt-out” from our newsletter or
        promotional communications if you no longer wish to receive them. Please
        note that you however may be unable to opt-out of certain
        service-related communications (for example communications about your
        subscription, billing or security).
        <br />
        <br />
        <strong>Blocking Cookies.</strong> You can remove or block certain
        cookies using the settings in your browser but the Properties may cease
        to function properly if you do so. For more information, please see
        <Link to="/cookies/"> Customerly's Cookie Policy.</Link> <br />
        <br />
        <strong>Do Not Track Signals (DNT).</strong> Your browser may have a “do
        not track” setting which, when enabled, causes your browser to send a do
        not track HTTP header file or “signal” to each site you visit. At the
        moment, our Properties do not respond to this type of signal.
      </p>
      <Heading level={3}>
        Accessing and Updating Your Personal Information
      </Heading>
      <p>
        When you use Our Services, we make good faith efforts to provide you
        with access to your personal information upon your request and either
        provide you the means to correct this information if it is inaccurate or
        to delete such information at your request if it is not otherwise
        required to be retained by law or for legitimate business purposes.{" "}
        <br />
        <br />
        You may access, review, correct, update, change or delete your
        information at any time. To do so, please contact us at
        legal@customerly.io with your name and the information requested to be
        accessed, corrected or removed, or if you are using the Service, sign in
        to your account, go to your profile settings, and make the desired
        changes. <br />
        <br />
        We may decline to process requests that are unreasonably repetitive or
        systematic, require disproportionate technical effort, jeopardize the
        privacy of others or would be extremely impractical. <br />
        <br />
        In any case where we provide information access and correction, we
        perform this service free of charge, except if doing so would require a
        disproportionate effort. <br />
        <br />
        Please note that if you cease using Our Services or we terminate your
        access to Our Services in accordance with Our Customer Agreement, you
        may no longer have the ability to access or update your information.{" "}
        <br />
        <br />
        We may retain your information as necessary to support the Services,
        comply with our legal obligations or resolve disputes.
      </p>
      <Heading level={3}>No Children Under Age 16</Heading>
      <p>
        The Offerings are not intended for use by anyone under the age of 16,
        nor does Customerly knowingly collect or solicit personal information
        from anyone under the age of 16. <br />
        <br />
        If you are under 16, you may not attempt to register for the Services or
        send any information about yourself to us, including your name, address,
        telephone number, or email address. <br />
        <br />
        In the event that we confirm that we have collected personal information
        from someone under the age of 16 without verification of parental
        consent, we will delete that information promptly.
        <br />
        <br /> If you are a parent or legal guardian of a child under 16 and
        believe that we might have any information from or about such child,
        please contact us at the email or mailing address provided at the end of
        this Privacy Policy.
      </p>
      <Heading level={3}>Security</Heading>
      <p>
        The security of your personal information is our first priority. We
        maintain a variety of appropriate technical and organizational
        safeguards to protect your personal information. <br />
        <br />
        We limit access to personal information about you to employees who we
        believe reasonably need to come into contact with that information to
        provide services to you or in order to do their jobs. <br />
        <br />
        Further, we have implemented reasonable physical, electronic, and
        procedural safeguards designed to protect personal information about
        you.
        <br />
        <br />
        We encrypt the information in transit on our Properties using
        industry-standard Transport Layer Security (TLS) encryption technology.
      </p>
      <Heading level={3}>Data Location and Infrastructure</Heading>
      <p>
        All of our services and data are hosted in AWS cloud facilities in{" "}
        <strong>Ireland</strong> and protected by AWS security, as described at{" "}
        <a href="https://aws.amazon.com/security/sharing-the-security-responsibility">
          https://aws.amazon.com/security/sharing-the-security-responsibility
        </a>
        . We do not run our own physical servers and{" "}
        <strong>we do not run production servers outside the EU</strong>.
      </p>
      <Heading level={3} id={"mobile"}>
        Mobile Apps Privacy Policy
      </Heading>
      <p>
        Our mobile app collects user-generated content, such as images, files,
        audio, and videos, solely for the purpose of allowing our users to
        attach this content to support messages they send to their clients.{" "}
        <br />
        <br />
        By using our app, you understand and consent to the collection and use
        of this content for this purpose.
        <br />
        <br />
        We will not use this content for any other purpose or share it with any
        third parties without your express consent. <br />
        <br />
        The app and its servers are compliant to industry standard security
        measures to safeguard the data shared by you and will be used only for
        the purpose as it was intended for. <br />
        <br />
        We will not share or disclose this content to any third parties except
        as necessary to provide support or as required by law.
      </p>
      <Heading level={3}>Changes to the Privacy Policy</Heading>
      <p>
        We reserve the right to modify this Privacy Policy at any time, so
        please review it frequently. Changes and clarifications will take effect
        immediately upon their posting on the website. If we make material
        changes to this Privacy Policy, we will notify you by email or through
        notice on our Services, so that you are aware of it.
      </p>
      <Heading level={3}>Contacts</Heading>
      <p>
        If there are any questions regarding this privacy policy, you may
        contact us using the information below. <br />
        Customerly Limited
        <br />
        Ground Floor, 71 Lower Baggot Street <br />
        Dublin <br />
        D02 P593 <br />
        legal@customerly.io <br />
        +353830399505 <br />
      </p>
    </Service>
  );
};
