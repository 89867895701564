import React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { Post } from "app-types/blog";
import { useTranslation } from "react-i18next";
import Heading from "components/core/Typography/Heading";

import scrollTo from "gatsby-plugin-smoothscroll";

import {
  CenteredCol,
  Date,
  Divider,
  Metadata,
  MetadataPostId,
  PostContainer,
  PostContent,
  PostContentContainer,
  SubtitleContainer,
  TitleContainer,
} from "./index.style";
import { Link } from "gatsby";
import SafeLink from "components/core/SafeLink";
import { BLOG_PATH } from "constants/paths";
import { Container, Row } from "styled-bootstrap-grid";
import { getReadingTime, getWordsCount } from "utils/reading-time";
import { createRendererAst } from "utils/render-ast";
import {
  CannedResponseTemplatePreview,
  ChatTemplatePreview,
  EmailTemplatePreview,
  FAQElement,
  H2ToBlogTitle,
  InLink,
  LightBoxPictureInDiv,
  LightBoxPictureInFigure,
  LinkFixer,
  NewsletterSubscribe,
  SignupBox,
  SocialShareBox,
  SurveyTemplatePreview,
} from "templates/blog/post/ast-components";
import BlogPostFooter from "components/ui/base/BlogPostFooter";
import {
  slugify,
  stringWithNumericReferencesToString,
  stripHtml,
  stripMultipleSpaces,
} from "utils/string";
import {
  CannedResponseTemplate,
  ChatTemplate,
  EmailTemplate,
  SurveyTemplate,
} from "app-types/template";
import BlogPostsBox from "components/ui/base/BlogPostsBox";
import { FAQPage, Question, WithContext } from "schema-dts";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

export interface BlogPostProps {
  pageContext: {
    post: Post;
    htmlAst: any;
    cannedResponsesTemplates: CannedResponseTemplate[];
    surveysTemplates: SurveyTemplate[];
    chatsTemplates: ChatTemplate[];
    emailsTemplates: EmailTemplate[];
  };
  location: Location;
}

interface Content {
  title: string;
  id: string;
}

const injectContentIntoAst = (
  ast: any,
  contents: Content[],
  imageData: IGatsbyImageData,
  postTitle: string,
  t: (key: string) => string
) => {
  let injected = false;
  const newAst: any[] = [];

  ast.children.forEach((node: any) => {
    newAst.push(node);

    if (!injected && node.tagName === "p") {
      // Inject the content after the first paragraph
      newAst.push({
        type: "element",
        tagName: "div",
        properties: { className: ["injected-content"] },
        children: [
          {
            type: "element",
            tagName: "figure",
            properties: {},
            children: [
              {
                type: "element",
                tagName: "PostFeaturedImage",
                properties: {
                  alt: postTitle,
                  image: imageData,
                },
                children: [],
              },
            ],
          },
          contents.length > 0 && {
            type: "element",
            tagName: "div",
            properties: {},
            children: [
              {
                type: "element",
                tagName: "p",
                properties: {},
                children: [
                  {
                    type: "text",
                    value: "Table of Contents",
                  },
                ],
              },
              {
                type: "element",
                tagName: "ul",
                properties: {},
                children: contents.map((content) => ({
                  type: "element",
                  tagName: "ClickableLinkPlaceholder",
                  properties: {
                    id: content.id,
                    title: content.title,
                  },
                  children: [],
                })),
              },
            ],
          },
        ].filter(Boolean),
      });

      injected = true;
    }
  });

  return { ...ast, children: newAst };
};

const BlogPost = ({ pageContext, location }: BlogPostProps) => {
  const { t } = useTranslation();
  const contents: Content[] = [];

  const getFullPostPath = (uri: string): string => {
    const baseUrl = process.env.GATSBY_SITE_URL || "https://www.customerly.io";

    return `${baseUrl}${BLOG_PATH}${uri}/`;
  };

  const h2RegExp = new RegExp(/<h2[^>]*?>(.*?)<\/h2>/gim);
  pageContext.post.content
    .match(h2RegExp)
    ?.map((title) =>
      stripMultipleSpaces(stringWithNumericReferencesToString(stripHtml(title)))
    )
    .filter((title) => title !== "")
    .forEach((title) => {
      contents.push({
        title,
        id: slugify(title),
      });
    });

  const faqRegex = /<faq>(.*?)<\/faq>/gs;
  const h3Regex = /<h3>(.*?)<\/h3>/gs;
  const spanRegex = /<span>(.*?)<\/span>/gs;

  const modifiedAst = injectContentIntoAst(
    pageContext.htmlAst,
    contents,
    pageContext.post.bigFeaturedImage.node.localFile.childImageSharp
      .gatsbyImageData,
    pageContext.post.title,
    t
  );

  // @ts-ignore
  const renderAst = createRendererAst({
    a: LinkFixer,
    figure: LightBoxPictureInFigure,
    div: LightBoxPictureInDiv,
    faq: (props: any) => FAQElement(props),
    inlink: (props: any) => InLink(props),
    signup: (props: any) => SignupBox(props, pageContext.post.slug),
    social: (props: any) =>
      SocialShareBox(
        props,
        encodeURIComponent(getFullPostPath(pageContext.post.slug))
      ),
    subscribe: () => NewsletterSubscribe(pageContext.post.slug),
    survey: (props: any) =>
      SurveyTemplatePreview(props, pageContext.surveysTemplates),
    email: (props: any) =>
      EmailTemplatePreview(props, pageContext.emailsTemplates),
    chat: (props: any) =>
      ChatTemplatePreview(props, pageContext.chatsTemplates),
    "canned-response": (props: any) =>
      CannedResponseTemplatePreview(
        props,
        pageContext.cannedResponsesTemplates
      ),
    h2: (props: any) => H2ToBlogTitle(props, location),
    PostFeaturedImage: (props: any) => {
      return (
        <GatsbyImage
          alt={props.alt}
          image={props.image}
          style={{ width: "100%", height: "400px" }}
        />
      );
    },
    ClickableLinkPlaceholder: (props: any) => {
      return (
        <li>
          <a
            href={`#${props.id}`}
            onClick={(e) => {
              e.preventDefault();
              scrollTo(`#${props.id}`, "center");
            }}
            style={{ cursor: "pointer" }}
          >
            {props.title}
          </a>
        </li>
      );
    },
  });

  // @ts-ignore: Type is not recognized but is correct
  const faqsStructuredData: Question[] | undefined = pageContext.post.content
    .match(faqRegex)
    ?.map((faq) => {
      const h3 = faq?.match(h3Regex);
      const span = faq?.match(spanRegex);

      return (
        h3 &&
        span && {
          "@type": "Question",
          name: stripHtml(h3[0]),
          acceptedAnswer: {
            "@type": "Answer",
            text: stripHtml(span[0]),
          },
        }
      );
    })
    .filter((content) => !!content);

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={pageContext.post.title}
        description={pageContext.post.seo.metaDesc}
        image={{
          imageData:
            pageContext.post.bigFeaturedImage.node.localFile.childImageSharp
              .gatsbyImageData,
          alt: pageContext.post.title,
        }}
        meta={[
          {
            name: "twitter:label1",
            content: t("templates.blog.post.estReadingTime"),
          },
          {
            name: "twitter:data1",
            content: `${getReadingTime(pageContext.post.content)} ${t(
              "templates.blog.post.minutes"
            )}`,
          },
        ]}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Article",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": location.href,
            },
            description: pageContext.post.seo.metaDesc,
            inLanguage: "en",
            isAccessibleForFree: "True",
            headline: pageContext.post.title,
            wordCount: getWordsCount(pageContext.post.content),
            image:
              location.origin +
              pageContext.post.bigFeaturedImage.node.localFile.childImageSharp
                .gatsbyImageData.images.fallback!.src,
            thumbnailUrl:
              location.origin +
              pageContext.post.bigFeaturedImage.node.localFile.childImageSharp
                .gatsbyImageData.images.fallback!.src,
            datePublished: pageContext.post.datePublished,
            dateModified: pageContext.post.dateModified,
            author: {
              "@type": "Person",
              name: pageContext.post.author.node.name,
            },
            publisher: {
              "@type": "Organization",
              name: "Customerly",
              url: "https://www.customerly.io",
              logo: {
                "@type": "ImageObject",
                url: "https://content.customerly.io/branding/logo/filled-with-notification.png",
              },
            },
          },
          ...(faqsStructuredData
            ? [
                {
                  "@context": "https://schema.org",
                  "@type": "FAQPage",
                  mainEntity: faqsStructuredData,
                } as WithContext<FAQPage>,
              ]
            : []),
        ]}
      />

      <PostContainer>
        <TitleContainer>
          <SubtitleContainer>
            <SafeLink to={`${BLOG_PATH}/`}>Blog</SafeLink>
            <Divider>•</Divider>
            <Link
              to={`${BLOG_PATH}${pageContext.post.categories.nodes[0].uri}/`}
            >
              {pageContext.post.categories.nodes[0].name}
            </Link>
          </SubtitleContainer>
          <Heading weight={"bold"} level={1}>
            {pageContext.post.title}
          </Heading>
          <Metadata>
            <SafeLink to={`${BLOG_PATH}${pageContext.post.author.node.uri}/`}>
              {pageContext.post.author.node.name}
            </SafeLink>

            <Divider>•</Divider>
            <Date>{pageContext.post.modified}</Date>
          </Metadata>
          <MetadataPostId
            aria-valuetext="databaseID"
            aria-level={pageContext.post.databaseId}
          />
        </TitleContainer>
      </PostContainer>

      <PostContentContainer>
        <Container>
          <Row>
            <CenteredCol>
              <PostContent>{renderAst(modifiedAst)}</PostContent>
              <BlogPostFooter
                name={pageContext.post.author.node.name}
                authorDescription={pageContext.post.author.node.description}
                description={pageContext.post.author.node.seo.metaDesc}
                img={pageContext.post.author.node.avatar.url}
                uri={pageContext.post.author.node.uri}
              />
              <BlogPostsBox
                maxPosts={2}
                postsPerRow={2}
                currentPostSlug={pageContext.post.slug}
                category={pageContext.post.categories.nodes[0].slug}
                title={t("templates.blog.post.moreArticles")}
              />
            </CenteredCol>
          </Row>
        </Container>
      </PostContentContainer>
    </GlobalWrapper>
  );
};

export default BlogPost;
