import React from "react";
import { StyledRegisterForBlog } from "components/ui/extended/RegisterForBlog/index.style";
import { useTranslation } from "react-i18next";
import Heading from "components/core/Typography/Heading";
import RegisterCTA from "components/ui/extended/RegisterCTA";

export interface RegisterForBlogProps {
  title?: string;
  description?: string;
  slug: string;
}

export default ({ title, description, slug }: RegisterForBlogProps) => {
  const { t } = useTranslation();

  const utmParams = {
    utm_source: "Customerly",
    utm_medium: "blog",
    utm_campaign: slug,
  };

  return (
    <StyledRegisterForBlog>
      {title && <Heading level={4}>{title}</Heading>}
      {description && <p>{description}</p>}
      <RegisterCTA utmParams={utmParams} />
    </StyledRegisterForBlog>
  );
};
