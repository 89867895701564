import React from "react";
import Service from "templates/service";
import Heading from "components/core/Typography/Heading";
import { useTranslation } from "react-i18next";
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  PINTEREST_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from "constants/social";

export interface SubprocessorsProps {
  location: Location;
}

export default ({ location }: SubprocessorsProps) => {
  const { t } = useTranslation();

  return (
    <Service
      title={t("pages.subprocessors.title")}
      subtitle={t("pages.subprocessors.subtitle")}
      seoProps={{
        title: t("pages.subprocessors.seo.title"),
        description: t("pages.subprocessors.seo.description"),
        image: {
          relativePath: "meta/customerly-live-chat-privacy.jpg",
          alt: "Customerly Subprocessors",
        },
        structuredData: [
          {
            "@context": "https://schema.org",
            "@type": "Corporation",
            brand: "Customerly",
            name: "Live Chat Software",
            sameAs: [
              FACEBOOK_URL,
              INSTAGRAM_URL,
              LINKEDIN_URL,
              PINTEREST_URL,
              TWITTER_URL,
              YOUTUBE_URL,
            ],
            url: "https://www.customerly.io/",
          },
        ],
      }}
      location={location}
    >
      <Heading level={1}>Subprocessors List</Heading>
      <p>
        <em>Effective date: 17 October 2024</em>
      </p>
      <p>
        Customerly is a data processor and engages certain onward subprocessors
        that may process personal data submitted to Customerly’s services by the
        controller. These subprocessors are listed below, along with a
        description of the service and the location where data is hosted.
      </p>

      <Heading level={2}>EU Data Hosting</Heading>
      <p>
        For customers participating in EU data hosting (hosted in{" "}
        <strong>Dublin, Ireland</strong> via AWS), the following subprocessors
        apply:
      </p>
      <ul>
        <li>
          <strong>Amazon Web Services (AWS)</strong> - Hosting, storage, and
          processing of customer data (Ireland, EU).
        </li>
        <li>
          <strong>OpenSignal</strong> - Push notification delivery service (EU).
        </li>
        <li>
          <strong>Microsoft Azure</strong> - Provides AI products and model
          training (EU).
        </li>
        <li>
          <strong>Cloudflare</strong> - Provides Transport Layer Security (TLS)
          protection and content delivery services (EU).
        </li>
      </ul>

      <Heading level={2}>Data Protection</Heading>
      <p>
        Customerly adheres to the GDPR regulation and maintains strict policies
        to ensure data security and privacy. We do not transfer or process user
        data outside the European Union (EU) under EU Data Hosting agreements.
        For further details, please refer to our full
        <a href="/gdpr/">GDPR</a>.
      </p>

      <Heading level={2}>Updates to This List</Heading>
      <p>
        This list may be updated by Customerly from time to time. Any
        significant changes will be communicated to our customers in accordance
        with our legal agreements.
      </p>
    </Service>
  );
};
