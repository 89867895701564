import styled from "styled-components";
import { StyledRegisterCTA } from "components/ui/extended/RegisterCTA/index.style";
import { media } from "styled-bootstrap-grid";

const StyledRegisterForBlog = styled.div`
  margin: 60px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.md`
    margin: 60px -10px;
  `}
  ${media.lg`
    margin: 60px -260px;
  `}
    h4 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  padding: 64px 32px;
  border-radius: 16px;
  background: ${(props) => props.theme.accentLighten90Color};

  ${StyledRegisterCTA} {
    width: fit-content;

    a {
      color: ${(props) => props.theme.whiteColor};
    }
  }
`;

export { StyledRegisterForBlog };
